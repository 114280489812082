import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  CardTitle,
  Form,
  Header2,
  InputCheckbox,
  InputError,
  InputGroup,
  InputHidden,
  InputLabel,
  InputMask,
  InputRadio,
  InputSelect,
  InputText,
  InputWrapper,
  Modal,
} from 'fiducius-ui';

import { safeAccess } from '../../utils';
import InputWrapperSpaced from '../styles/input-wrapper-spaced';

const timeZones = {
  '': '',
  'America/New_York': '(UTC-05:00) Eastern Time',
  'America/Chicago': '(UTC-06:00) Central Time',
  'America/Denver': '(UTC-07:00) Mountain Time',
  'America/Los_Angeles': '(UTC-08:00) Pacific Time',
  'America/Anchorage': '(UTC-09:00) Alaska Time',
  'America/Adak': '(UTC-10:00) Hawaii–Aleutian Time',
};

const StyledCheckbox = styled.div`
  & > label.input-checkbox {
    margin-bottom: 0;
    color: ${(p) => p.theme.textPrimary};
  }
`;

const TextMessageText = styled.div`
  margin-left: 2rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const StyledCardText = styled(CardText)`
  text-align: left;
`;

class SignUpInfoForm extends React.Component {
  static propTypes = {
    data: t.object,
    domain: t.string.isRequired,
    errors: t.object,
    handleChange: t.func.isRequired,
    locations: t.object,
    onSubmit: t.func.isRequired,
    onBack: t.func.isRequired,
    rememberMe: t.bool,
    showLocations: t.bool,
    showNFPQuestion: t.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      showInstructions: false,
    };
    this.handleInstructionsButton = this.handleInstructionsButton.bind(this);
  }

  handleInstructionsButton() {
    if (!this.state.showInstructions) {
      window.scrollTo(0, 0);
    }
    this.setState({ showInstructions: !this.state.showInstructions });
  }

  render() {
    const {
      data = {},
      domain,
      errors = {},
      handleChange,
      locations,
      onSubmit,
      showLocations,
      onBack,
      showNFPQuestion,
    } = this.props;

    return (
      <>
        <Form
          {...this.props}
          handleChange={handleChange}
          id="signUpInfoForm"
          onSubmit={onSubmit}
          defaults={{
            daylightSavings: true,
            partnerMemberId: '',
            phoneNumber: '',
            textNotifications: true,
          }}
        >
          <Header2>Tell us about yourself</Header2>
          <div className="row no-gutters">
            <div className="col-4">
              <InputWrapperSpaced error={!!errors.firstName} first>
                <InputLabel htmlFor="firstName" required>
                  First Name
                </InputLabel>
                <InputError>{safeAccess(errors, 'firstName.detail')}</InputError>
                <InputGroup>
                  <InputText name="firstName" defaultValue={data.firstName} />
                </InputGroup>
              </InputWrapperSpaced>
            </div>
            <div className="col-2">
              <InputWrapperSpaced error={!!errors.middleInitial}>
                <InputLabel htmlFor="middleInitial">M.I.</InputLabel>
                <InputError>{safeAccess(errors, 'middleInitial.detail')}</InputError>
                <InputGroup>
                  <InputText name="middleInitial" maxLength="1" defaultValue={data.middleInitial} />
                </InputGroup>
              </InputWrapperSpaced>
            </div>
            <div className="col-4">
              <InputWrapperSpaced error={!!errors.lastName}>
                <InputLabel htmlFor="lastName" required>
                  Last Name
                </InputLabel>
                <InputError>{safeAccess(errors, 'lastName.detail')}</InputError>
                <InputGroup>
                  <InputText name="lastName" defaultValue={data.lastName} />
                </InputGroup>
              </InputWrapperSpaced>
            </div>
            <div className="col-2">
              <InputWrapperSpaced error={!!errors.suffix}>
                <InputLabel htmlFor="suffix">Suffix</InputLabel>
                <InputError>{safeAccess(errors, 'suffix.detail')}</InputError>
                <InputGroup>
                  <InputText name="suffix" defaultValue={data.suffix} />
                </InputGroup>
              </InputWrapperSpaced>
            </div>
          </div>
          <InputWrapper error={!!errors.dob}>
            <InputLabel htmlFor="dob" required>
              Birth Date
            </InputLabel>
            <InputError>{safeAccess(errors, 'dob.detail')}</InputError>
            <InputGroup affordance="medium">
              <InputMask
                defaultValue={data.dob}
                mask="##/##/####"
                name="dob"
                placeholder="MM/DD/YYYY"
              />
            </InputGroup>
          </InputWrapper>
          {showLocations && (
            <InputWrapper error={!!errors.partnerMemberId}>
              <InputLabel htmlFor="partnerMemberId" required>
                Location
              </InputLabel>
              <InputError>{safeAccess(errors, 'partnerMemberId.detail')}</InputError>
              <InputGroup affordance="large">
                <InputSelect name="partnerMemberId" options={locations} defaultValue={''} />
              </InputGroup>
            </InputWrapper>
          )}
          <InputWrapper error={!!errors.timeZone}>
            <InputLabel htmlFor="timeZone" required>
              Time Zone
            </InputLabel>
            <InputError>{safeAccess(errors, 'timeZone.detail')}</InputError>
            <InputGroup affordance="large">
              <InputSelect name="timeZone" options={timeZones} defaultValue={data.timeZone} />
            </InputGroup>
            <StyledCheckbox>
              <InputCheckbox
                name="daylightSavings"
                label="Observe daylight savings"
                defaultChecked
              />
            </StyledCheckbox>
          </InputWrapper>
          <InputWrapper error={!!errors.phoneNumber}>
            <InputLabel htmlFor="phoneNumber" required>
              Phone Number
            </InputLabel>
            <InputError>{safeAccess(errors, 'phoneNumber.detail')}</InputError>
            <InputGroup affordance="medium">
              <InputMask
                defaultValue={data.phoneNumber}
                mask="(###) ###-####"
                name="phoneNumber"
                placeholder="(___) ___-____"
                type="tel"
              />
            </InputGroup>
            <StyledCheckbox>
              <InputCheckbox
                name="textNotifications"
                defaultChecked={true}
                label="I agree to receive text messages from Fiducius."
              />
            </StyledCheckbox>
            <TextMessageText>
              Message and data rates may apply. Message frequency varies. Reply HELP for help and
              STOP to cancel. View our <a href="/terms-of-use">Terms of Use</a> and{' '}
              <a href="/privacy">Privacy Policy</a>.
            </TextMessageText>
          </InputWrapper>
          <InputHidden name="domain" value={domain} />
          {showNFPQuestion && (
            <InputWrapper error={!!errors.clientNonProfit}>
              <InputLabel htmlFor="clientNonProfit" required>
                Which of the following describes the organization you work for?
              </InputLabel>
              <InputError>{safeAccess(errors, 'clientNonProfit.detail')}</InputError>
              <div className="row">
                <div className="col-6">
                  <InputRadio
                    name="clientNonProfit"
                    value="true"
                    label="Not-for-profit organization"
                    defaultChecked={data.clientNonProfit === 'true'}
                  />
                  <InputRadio
                    name="clientNonProfit"
                    value="false"
                    label="For-profit organization"
                    defaultChecked={data.clientNonProfit === 'false'}
                  />
                </div>
                <div className="col-6">
                  <Button brand="primary" onClick={this.handleInstructionsButton}>
                    <FontAwesomeIcon icon={faQuestionCircle} fixedWidth /> Kinds of Organizations
                  </Button>
                </div>
              </div>
            </InputWrapper>
          )}
          <ButtonWrapper>
            <Button brand="lowContrast" onClick={onBack}>
              Back
            </Button>
            <Button brand="primary" type="submit" onClick={onSubmit}>
              Sign up
            </Button>
          </ButtonWrapper>
        </Form>
        <Modal isOpen={this.state.showInstructions}>
          <Card>
            <CardBody>
              <CardTitle>Kinds of Organizations</CardTitle>
              <StyledCardText>
                <p>
                  <strong>Not-for-profit organizations</strong> operate to provide a community,
                  public or social benefit, and are driven by mission rather than profit or
                  shareholder returns. They are typically registered as a 501(c)3 organization and
                  are exempt from paying income tax. Funding sources may include grants and
                  donations in addition to revenue. Examples include non-profit hospitals; public
                  schools and universities; government agencies; charities; etc.
                </p>
                <p>
                  <strong>For-profit organizations</strong> operate with the goal of maximizing
                  revenue and profitability for owners and investors. They pay taxes based on their
                  net income. Examples include retailers; banks; restaurants; technology providers;
                  most media companies; private practice healthcare providers and some hospitals;
                  etc.
                </p>
                <p>
                  If you are still unsure which type of organization you work for, please select
                  "Not-for-profit organization" for the purposes of this application.
                </p>
              </StyledCardText>
            </CardBody>
            <CardFooter>
              <Button brand="lowContrast" onClick={this.handleInstructionsButton}>
                Close
              </Button>
            </CardFooter>
          </Card>
        </Modal>
      </>
    );
  }
}

export default SignUpInfoForm;
