import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';

import { calculateCustomerPermissions } from '../../auth';

export const PermissionContext = React.createContext({
  permissions: {
    clientStatus: null,
    forgivenessSteps: null,
    hasAnyBenefits: false,
    inPreSale: false,
    isFullService: false,
    isMarried: false,
    inForgiveness: false,
    showAccounts: false,
    showAccountServicers: false,
    showContribution: false,
    showForgiveness: false,
    showIdentity: false,
    showPayNow: false,
    showRefinance: false,
    showRetirement: false,
    showScheduleMeeting: false,
    showSharedData: false,
    showSimplifi: false,
    showSimplifiConsolidation: false,
    showSimplifiIDR: false,
    showStudentLoanDisabilityInsurance: false,
    showTuition: false,
    covidFurlough: 0,
    useMFA: 0,
    securityQuestionsSetup: false,
    consentFormCode: null,
    hasConsentToShareData: false,
    hasPayNow: false,
    electronicEmpcertEnabled: false,
    hasFreshStart: false,
    hasReducedIDR: false,
    repaymentPlan: null,
    enterpriseEnabled: false,
    hasEducationalReengagement: false,
    hasOptimalSituation: false,
    subscriptionAvailable: false,
    subscriptionActive: false,
    hasBenefitTools: false,
    disableScheduling: false,
    hasMichiganProgram: false,
    showSkipScreening: false,
    skipScreeningMessage: null,
    isConsolidationSubmitGeneratePaused: false,
    hasPlaid: false,
    hasLoanRepayment: false,
    hasEmployerPaidRenewal: false,
  },
});

const mapStateToProps = (state) => calculateCustomerPermissions(state);

export const PermissionProvider = connect(mapStateToProps)(({ children, ...props }) => (
  <PermissionContext.Provider value={props}>{children}</PermissionContext.Provider>
));

PermissionProvider.propTypes = {
  children: t.node,
  permissions: t.shape({
    clientStatus: t.string,
    forgivenessSteps: t.array,
    hasAnyBenefits: t.bool,
    inPreSale: t.bool,
    isFullService: t.bool,
    isMarried: t.bool,
    inForgiveness: t.bool,
    showAccounts: t.bool,
    showAccountServicers: t.bool,
    showContribution: t.bool,
    showForgiveness: t.bool,
    showIdentity: t.bool,
    showPayNow: t.bool,
    showRefinance: t.bool,
    showRetirement: t.bool,
    showScheduleMeeting: t.bool,
    showSharedData: t.bool,
    showSimplifi: t.bool,
    showSimplifiConsolidation: t.bool,
    showSimplifiIDR: t.bool,
    showStudentLoanDisabilityInsurance: t.bool,
    showTuition: t.bool,
    covidFurlough: t.number,
    useMFA: t.number,
    securityQuestionsSetup: t.bool,
    consentFormCode: t.string,
    hasConsentToShareData: t.bool,
    hasPayNow: t.bool,
    electronicEmpcertEnabled: t.bool,
    hasFreshStart: t.bool,
    repaymentPlan: t.string,
    enterpriseEnabled: t.bool,
    hasEducationalReengagement: t.bool,
    hasOptimalSituation: t.bool,
    subscriptionAvailable: t.bool,
    subscriptionActive: t.bool,
    hasBenefitTools: t.bool,
    disableScheduling: t.bool,
    hasMichiganProgram: t.bool,
    showSkipScreening: t.bool,
    skipScreeningMessage: t.string,
    isConsolidationSubmitGeneratePaused: t.bool,
    isConsolidationCheckPaused: t.bool,
    isEmpcertCheckPaused: t.bool,
    annualRecertDate: t.string,
    hasPlaid: t.bool,
  }),
};

export const withPermissions = (Component) => (props) => (
  <PermissionContext.Consumer>
    {(context) => <Component {...props} {...context} />}
  </PermissionContext.Consumer>
);

export default withPermissions;
