import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle, CardText, DashboardSection, Header5 } from 'fiducius-ui';

import { safeAccess, mergeRequestStatuses, formatAsMoney } from '../../../utils';

import { getUserId, authLoadPermissions } from '../../../auth';
import { withPermissions } from '../../../routing';

import RenewalConfirmation from '../components/renewal-confirmation';
import ForgivenessProgress from '../components/forgiveness-progress';
import { renewalResultsLoadResource, renewalResultsUpdateResource } from '../../../renewal-results';
import DocsNeededForRSUpload from '../components/docs-upload-needed';
import {
  getForgivenessData,
  getPastYearSavings,
  getCurrentYearSavings,
  getSAVEPlanSavings,
} from '../redux/selectors';
import { forgivenessLoadResource } from '../redux/operations';

class FeeRS extends React.Component {
  static propTypes = {
    request: t.object.isRequired,
    permissions: t.object.isRequired,
    loadForgiveness: t.func.isRequired,
    pastYearSavings: t.object,
    currentYearSavings: t.object,
    saveProgramSavings: t.object,
    forgivenessData: t.object,
    data: t.object,
    loadData: t.func.isRequired,
    acknowledgeRsFeeCredits: t.func.isRequired,
    id: t.string,
  };

  constructor(props) {
    super(props);
    this.state = { feePaid: false };
  }

  componentDidMount() {
    this.props.loadData(this.props.id);
    this.setState({ feePaid: false });
    const { id, loadForgiveness, forgivenessData, permissions } = this.props;
    if (permissions.hasForgiveness && Object.keys(forgivenessData).length == 0) {
      loadForgiveness(id);
    }
  }

  componentDidUpdate() {
    if (
      this.state.feePaid !== this.props.data.paymentReceived &&
      this.props.data.paymentReceived !== undefined
    ) {
      this.setState({ feePaid: true });
      this.props.loadData(this.props.id);
    }
  }

  acknowledgeFee = async () => {
    await this.props.acknowledgeRsFeeCredits(this.props.id);
    this.props.loadData(this.props.id);
  };

  getFeeText = () => {
    const {
      data,
      pastYearSavings,
      currentYearSavings,
      saveProgramSavings,
      forgivenessData,
    } = this.props;
    if (data !== undefined && data !== null) {
      if (data.renewalBalance > 0 && !data.paymentReceived) {
        const showSavingsText = pastYearSavings > 0 && currentYearSavings > 0;
        const showSAVESavingsText = saveProgramSavings > 0;
        const showIncreaseText = forgivenessData.islsIdrPayment > 0;
        let savingsAmountText =
          'We anticipate you could save approximately ' +
          formatAsMoney(saveProgramSavings) +
          ' under the new plan.';

        return (
          <>
            <p>The next step of the renewal process is to pay your renewal fee.</p>
            {showSavingsText && !showSAVESavingsText && (
              <p>
                You had estimated savings of approximately {formatAsMoney(pastYearSavings)} this
                past year and we anticipate you'll save {formatAsMoney(currentYearSavings)} after
                completing your renewal for this year.
              </p>
            )}
            {showSavingsText && showSAVESavingsText && (
              <p>
                You had estimated savings of approximately {formatAsMoney(pastYearSavings)} this
                past year and we anticipate you'll save {formatAsMoney(currentYearSavings)} after
                completing your renewal for this year. And with the recent announcement of the SAVE
                Repayment plan, your savings could increase! {showIncreaseText && savingsAmountText}
              </p>
            )}
            <p>{!data.selfService && <DocsNeededForRSUpload documentButton={true} />}</p>
          </>
        );
      } else if (data.hasEmployerPaidRenewal) {
        return (
          <>
            <p>Your renewal fee is paid for by your employer.</p>
          </>
        );
      } else if (data.renewalBalance === 0 && !data.paymentReceived) {
        return (
          <>
            <p>
              Your renewal fee of {formatAsMoney(data.serviceFee)} is paid in full due to renewal
              credits applied to your account
            </p>
          </>
        );
      } else if (data.paymentReceived) {
        return (
          <>
            <p>
              Your renewal fee of {formatAsMoney(data.serviceFee)} has been set up and received.
            </p>
          </>
        );
      }
    }
  };

  render() {
    const { data } = this.props;
    return (
      <>
        <ForgivenessProgress />
        <DashboardSection>
          <Card brand="primary">
            <CardBody>
              <CardTitle>Renewal Fee</CardTitle>
              <CardText>
                <Header5>{data && this.getFeeText()}</Header5>
              </CardText>
            </CardBody>
            <RenewalConfirmation
              stepId={250}
              zeroBalance={data && data.renewalBalance === 0 && !data.paymentReceived}
            />
          </Card>
        </DashboardSection>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    id: getUserId(state),
    data: safeAccess(state, `renewalResults.cache[${getUserId(state)}]`) || {},
    request: mergeRequestStatuses([
      state.renewalResults.requests.loadResource,
      state.renewalResults.requests.updateResource,
    ]),
    forgivenessData: getForgivenessData(state),
    pastYearSavings: getPastYearSavings(state),
    currentYearSavings: getCurrentYearSavings(state),
    saveProgramSavings: getSAVEPlanSavings(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: async (id) => {
    dispatch(renewalResultsLoadResource(id));
  },
  acknowledgeRsFeeCredits: async (id) => {
    await dispatch(renewalResultsUpdateResource(id));
    dispatch(authLoadPermissions());
  },
  loadForgiveness: (id) => dispatch(forgivenessLoadResource(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(FeeRS));
