import React from 'react';
import { SharedDataConsent } from '../../legal';
import { paymentSetTypeDeposit, paymentSetTypeService } from '../../payment/redux/operations';
//import { RenewalWantsMeeting } from '../../workflows/renewal';

export const todoActionTypes = {
  TODO_ACTION_DRAWER: 'TODO_ACTION_DRAWER',
  TODO_ACTION_LINK: 'TODO_ACTION_LINK',
  TODO_ACTION_MODAL: 'TODO_ACTION_MODAL',
  TODO_ACTION_READONLY: 'TODO_ACTION_READONLY',
  TODO_ACTION_REDUX: 'TODO_ACTION_REDUX',
  TODO_ACTION_LINKPAYSERVICE: 'TODO_ACTION_LINKPAYSERVICE',
};

const drawerCallback = (drawer) => (toggleDrawer) => () => toggleDrawer(drawer);

const linkCallback = (route, preNavigate = null) => (history, dispatch, toggleDrawer) => () => {
  preNavigate && preNavigate(dispatch);
  history.push(route);
  toggleDrawer();
};

const linkCallbackPayService = (route, preNavigate = null) => (
  history,
  dispatch,
  toggleDrawer,
  serviceId
) => () => {
  preNavigate && preNavigate(dispatch, serviceId);
  history.push(route);
  toggleDrawer();
};

const modalCallback = (Component) => (props) => <Component {...props} />;

/*const reduxCallback = (action) => (id, dispatch) => () => action(id, dispatch);

const standardDispatch = async (id, dispatch) => {
  await dispatch(todosCopyResourceToForm(id));
  await dispatch(todosUpdateResource(id, id));
  dispatch(fulfillmentLoadCollection());
  dispatch(todosLoadCollection());
};*/

export const todoMap = {
  0: {
    id: 0,
    _apiName: 'screening',
    action: linkCallback('/screening'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Screening',
  },
  1: {
    id: 1,
    _apiName: 'scheduleMeeting',
    action: drawerCallback('meetings'),
    actionType: todoActionTypes.TODO_ACTION_DRAWER,
    buttonText: 'Schedule A Meeting',
  },
  2: {
    id: 2,
    _apiName: 'docUpload',
    action: linkCallback('/documents'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To My Documents',
  },
  3: {
    id: 3,
    _apiName: 'agreement',
    action: linkCallback('/agreement'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To My Agreement',
  },
  4: {
    id: 4,
    _apiName: 'docsToSign',
    action: linkCallback('/documents'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Sign Documents',
  },
  5: {
    id: 5,
    _apiName: 'payDeposit',
    action: linkCallback('/payment', (dispatch) => dispatch(paymentSetTypeDeposit())),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Pay Deposit',
  },
  6: {
    id: 6,
    _apiName: 'sharedDataConsent',
    action: modalCallback(SharedDataConsent),
    actionType: todoActionTypes.TODO_ACTION_MODAL,
    buttonText: 'Consent To Share Data',
  },
  7: {
    id: 7,
    _apiName: 'authorizedUse',
    action: linkCallback('/benefits/forgiveness/welcome'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  90: {
    id: 90,
    _apiName: 'cancelMeeting',
    action: drawerCallback('meetings'),
    actionType: todoActionTypes.TODO_ACTION_DRAWER,
    buttonText: 'Cancel My Meeting',
  },
  10: {
    id: 10,
    _apiName: 'updateProfile',
    action: linkCallback('/benefits/forgiveness/welcome'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  11: {
    id: 11,
    _apiName: 'updateServicers',
    action: linkCallback('/benefits/forgiveness/welcome'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  12: {
    id: 12,
    _apiName: 'updateReferences',
    action: linkCallback('/benefits/forgiveness/welcome'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  13: {
    id: 13,
    _apiName: 'updateAccounts',
    action: linkCallback('/accounts'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Update My Accounts',
  },
  14: {
    id: 14,
    _apiName: 'scheduleConferenceCall',
    action: drawerCallback('meetings'),
    actionType: todoActionTypes.TODO_ACTION_DRAWER,
    buttonText: 'Schedule A Conference Call',
  },
  15: {
    id: 15,
    _apiName: 'updateContributions',
    action: linkCallback('/benefits/contribution'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Update My Contributions',
  },
  101: {
    id: 101,
    _apiName: 'informationComplete',
    action: linkCallback('/benefits/forgiveness/welcome'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Confirm Information Collection Complete', // dummy
  },
  102: {
    id: 102,
    _apiName: 'forbearancePhase1Complete',
    action: linkCallback('/benefits/forgiveness/forbearance-p1'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  103: {
    id: 103,
    _apiName: 'consolidationGenerated',
    action: linkCallback('/benefits/forgiveness/consolidate/step-1'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  104: {
    id: 104,
    _apiName: 'consolidationConfirmation',
    action: linkCallback('/benefits/forgiveness/consolidate/step-2'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  105: {
    id: 105,
    _apiName: 'consolidationComplete',
    action: linkCallback('/benefits/forgiveness/consolidate/step-3'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  106: {
    id: 106,
    _apiName: 'empCertTransferGenerated',
    action: linkCallback('/benefits/forgiveness/empcert/step-1'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  107: {
    id: 107,
    _apiName: 'empCertTransferConfirmation',
    action: linkCallback('/benefits/forgiveness/empcert/step-2'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  108: {
    id: 108,
    _apiName: 'empCertTransferComplete',
    action: linkCallback('/benefits/forgiveness/empcert/step-3'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  109: {
    id: 109,
    _apiName: 'forbearancePhase2Complete',
    action: linkCallback('/benefits/forgiveness/forbearance-p2'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  110: {
    id: 110,
    _apiName: 'taxesFiled',
    action: linkCallback('/benefits/forgiveness/taxes'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  111: {
    id: 111,
    _apiName: 'idrSubmissionGenerated',
    action: linkCallback('/benefits/forgiveness/idr/step-1'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  112: {
    id: 112,
    _apiName: 'idrConfirmation',
    action: linkCallback('/benefits/forgiveness/idr/step-2'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  113: {
    id: 113,
    _apiName: 'idrComplete',
    action: linkCallback('/benefits/forgiveness/idr/step-3'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  114: {
    id: 114,
    _apiName: 'contributionIdrUpload',
    action: linkCallback('/benefits/forgiveness/contribution-ss/step-1'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  115: {
    id: 115,
    _apiName: 'contributionReview',
    action: linkCallback('/benefits/forgiveness/contribution-ss/step-2'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  150: {
    id: 150,
    _apiName: 'btsQuestionnaire',
    action: linkCallback('/benefits/forgiveness/bts-questionnaire'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  201: {
    id: 201,
    _apiName: 'renewalQuestionnaire',
    action: linkCallback('/renewal'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Renewal Questionnaire',
  },
  202: {
    id: 202,
    _apiName: 'renewalDocuments',
    action: linkCallback('/benefits/forgiveness/docs-needed'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  203: {
    id: 203,
    _apiName: 'renewalScheduleMeeting',
    action: linkCallback('/benefits/forgiveness/meeting'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  204: {
    id: 204,
    _apiName: 'renewalPayment',
    action: linkCallback('/benefits/forgiveness/fee'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  206: {
    id: 206,
    _apiName: 'renewalToBeSubmitted',
    action: () => {}, // dummy
    actionType: todoActionTypes.TODO_ACTION_READONLY,
    buttonText: 'Renewal To Be Submitted', // dummy
  },
  207: {
    id: 207,
    _apiName: 'renewalSubmitted',
    action: () => {}, // dummy
    actionType: todoActionTypes.TODO_ACTION_READONLY,
    buttonText: 'Renewal Submitted', // dummy
  },
  208: {
    // TODO: Send Opt-out patch to API, don't need feedback on elect meeting, but need to schedule
    // MUST set result-id to 1 for API during PATCH
    id: 208,
    _apiName: 'renewalChooseWantsMeeting',
    action: () => {}, // dummy
    actionType: todoActionTypes.TODO_ACTION_MODAL,
    buttonText: 'View Meeting Options',
  },
  209: {
    id: 209,
    _apiName: 'renewalUpdateProfile',
    action: linkCallback('/profile'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Update My Profile',
  },

  250: {
    id: 250,
    _apiName: 'renewalSsAnnualFeeCollected',
    action: linkCallback('/benefits/forgiveness/fee'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  251: {
    id: 251,
    _apiName: 'renewalSsForbearanceNeeded',
    action: linkCallback('/benefits/forgiveness/forbearance-rs'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  252: {
    id: 252,
    _apiName: 'renewalSsEmpCertGenerated',
    action: linkCallback('/benefits/forgiveness/empcert-ss/step-1'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  253: {
    id: 253,
    _apiName: 'renewalSsEmpCertConfirmation',
    action: linkCallback('/benefits/forgiveness/empcert-ss/step-2'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  254: {
    id: 254,
    _apiName: 'renewalSsEmpCertComplete',
    action: linkCallback('/benefits/forgiveness/empcert-ss/step-3'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  255: {
    id: 255,
    _apiName: 'renewalSsIDRGenerated',
    action: linkCallback('/benefits/forgiveness/idr-ss/step-1'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  256: {
    id: 256,
    _apiName: 'renewalSsIDRConfirmation',
    action: linkCallback('/benefits/forgiveness/idr-ss/step-2'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  257: {
    id: 257,
    _apiName: 'renewalSsIDRComplete',
    action: linkCallback('/benefits/forgiveness/idr-ss/step-3'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  258: {
    id: 258,
    _apiName: 'renewalSsYearComplete',
    action: linkCallback('/profile'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Oh, Hi There',
  },
  259: {
    id: 259,
    _apiName: 'renewalSsExtensionNeeded',
    action: linkCallback('/benefits/forgiveness/renewal-extension'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  260: {
    id: 260,
    _apiName: 'renewalSsIdrUpload',
    action: linkCallback('/benefits/forgiveness/contribution-rsss/step-1'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  261: {
    id: 261,
    _apiName: 'renewalSsIdrReview',
    action: linkCallback('/benefits/forgiveness/contribution-rsss/step-2'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },

  301: {
    id: 301,
    _apiName: 'simplifiUpdateReferences',
    action: linkCallback('/benefits/simplifi'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Update My References',
  },
  351: {
    id: 351,
    _apiName: 'uploadNSLDS',
    action: linkCallback('/benefits/forgiveness/nslds'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Upload Your NSLDS',
  },

  401: {
    id: 401,
    _apiName: 'tuitionUpdateApproverEmail',
    action: linkCallback('/benefits/tuition/overview'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Update Manager Email',
  },

  402: {
    id: 402,
    _apiName: 'tuitionCreateReimbursementServicer',
    action: linkCallback('/accounts'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Create Account Servicer for Tuition Reimbursement',
  },

  403: {
    id: 403,
    _apiName: 'tuitionCreateReimbursementAccount',
    action: linkCallback('/accounts'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Create Tuition Account',
  },

  411: {
    id: 411,
    _apiName: 'tuitionUploadPayment',
    action: linkCallback('/documents'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Upload Documents',
  },

  412: {
    id: 412,
    _apiName: 'tuitionUploadGrades',
    action: linkCallback('/documents'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Upload Documents',
  },

  413: {
    id: 413,
    _apiName: 'tuitionUploadPaymentAndGrades',
    action: linkCallback('/documents'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Upload Documents',
  },

  420: {
    id: 420,
    _apiName: 'tuitionCompleteTermInfo_Deprecated',
    action: linkCallback('/benefits/tuition/overview'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Edit Term _ Deprecated',
  },
  421: {
    id: 421,
    _apiName: 'tuitionUpdateHomeAddress',
    action: linkCallback('/profile'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Enter Home Address',
  },

  422: {
    id: 422,
    _apiName: 'tuitionCreateInstitution',
    action: linkCallback('/benefits/tuition/institutions'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Create Institution',
  },

  423: {
    id: 423,
    _apiName: 'tuitionCreateTerm',
    action: linkCallback('/benefits/tuition/terms'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Create Term',
  },

  424: {
    id: 424,
    _apiName: 'tuitionUpdateTerm',
    action: linkCallback('/benefits/tuition/terms'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Update Term',
  },

  425: {
    id: 425,
    _apiName: 'tuitionCreateClass',
    action: linkCallback('/benefits/tuition/classes'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Create Class',
  },

  426: {
    id: 426,
    _apiName: 'tuitionUpdateClassGrade',
    action: linkCallback('/benefits/tuition/classes'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Update Class',
  },

  501: {
    id: 501,
    _apiName: 'contributionAddServicer',
    action: linkCallback('/accounts'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Add Servicer/Bank',
  },
  502: {
    id: 502,
    _apiName: 'contributionAddAccount',
    action: linkCallback('/accounts'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Add Accounts',
  },
  503: {
    id: 503,
    _apiName: 'contributionProofRequired',
    action: linkCallback('/documents'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Upload Documents',
  },
  504: {
    id: 504,
    _apiName: 'contributionNoActiveAccounts',
    action: linkCallback('/benefits/contribution/active-contributions'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Select Accounts',
  },
  505: {
    id: 505,
    _apiName: 'contributionPSLFProofRequired',
    action: linkCallback('/benefits/forgiveness/contribution-ss/step-1'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go to Step',
  },
  506: {
    id: 506,
    _apiName: 'contributionPSLFRenewalProofRequired',
    action: linkCallback('/benefits/forgiveness/contribution-rsss/step-1'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go to Step',
  },
  602: {
    id: 602,
    _apiName: 'lwoUploadNslds',
    action: linkCallback('/benefits/limited-waiver-opportunity/loan-information'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Upload My NSLDS',
  },
  603: {
    id: 603,
    _apiName: 'lwoQuestionnaire',
    action: linkCallback('/benefits/limited-waiver-opportunity/questionnaire'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go to Step',
  },
  604: {
    id: 604,
    _apiName: 'lwoAgreement',
    action: linkCallback('/benefits/limited-waiver-opportunity/agreement'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go to Step',
  },
  605: {
    id: 605,
    _apiName: 'lwoPayFee',
    action: linkCallback('/benefits/limited-waiver-opportunity/fee'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go to Step',
  },
  606: {
    id: 606,
    _apiName: 'lwoGenerateEcf',
    action: linkCallback('/benefits/limited-waiver-opportunity/employment-certification/step-1'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go to Step',
  },
  607: {
    id: 607,
    _apiName: 'lwoSubmitEcf',
    action: linkCallback('/benefits/limited-waiver-opportunity/employment-certification/step-2'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go to Step',
  },
  608: {
    id: 608,
    _apiName: 'lwoCompleteEcf',
    action: linkCallback('/benefits/limited-waiver-opportunity/employment-certification/step-3'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go to Step',
  },
  609: {
    id: 609,
    _apiName: 'lwoQualifyingPaymentCheck',
    action: linkCallback('/benefits/limited-waiver-opportunity/confirmation'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go to Step',
  },
  700: {
    id: 700,
    _apiName: 'payService',
    action: linkCallbackPayService('/payment', (dispatch, id) =>
      dispatch(paymentSetTypeService(id))
    ),
    actionType: todoActionTypes.TODO_ACTION_LINKPAYSERVICE,
    buttonText: 'Go To Step',
  },
  701: {
    id: 701,
    _apiName: 'payService',
    action: linkCallbackPayService('/payment', (dispatch, id) =>
      dispatch(paymentSetTypeService(id))
    ),
    actionType: todoActionTypes.TODO_ACTION_LINKPAYSERVICE,
    buttonText: 'Go To Step',
  },
  702: {
    id: 702,
    _apiName: 'payService',
    action: linkCallbackPayService('/payment', (dispatch, id) =>
      dispatch(paymentSetTypeService(id))
    ),
    actionType: todoActionTypes.TODO_ACTION_LINKPAYSERVICE,
    buttonText: 'Go To Step',
  },
  703: {
    id: 703,
    _apiName: 'payService',
    action: linkCallbackPayService('/payment', (dispatch, id) =>
      dispatch(paymentSetTypeService(id))
    ),
    actionType: todoActionTypes.TODO_ACTION_LINKPAYSERVICE,
    buttonText: 'Go To Step',
  },
  704: {
    id: 704,
    _apiName: 'payService',
    action: linkCallbackPayService('/payment', (dispatch, id) =>
      dispatch(paymentSetTypeService(id))
    ),
    actionType: todoActionTypes.TODO_ACTION_LINKPAYSERVICE,
    buttonText: 'Go To Step',
  },

  // TODO: Insert 800s here for StandaloneEmpCert
  800: {
    id: 800,
    _apiName: 'empCertGenerate',
    action: linkCallback('/benefits/empcert/generate'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  801: {
    id: 801,
    _apiName: 'empCertApproval',
    action: linkCallback('/benefits/empcert/generate'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  805: {
    id: 805,
    _apiName: 'empCertAwaitingCorrection',
    action: linkCallback('/benefits/empcert/correction'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  810: {
    id: 810,
    _apiName: 'empCertSubmit',
    action: linkCallback('/benefits/empcert/submit'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  815: {
    id: 815,
    _apiName: 'empCertCheck',
    action: linkCallback('/benefits/empcert/check'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  820: {
    id: 820,
    _apiName: 'empCertCheck',
    action: linkCallback('/benefits/empcert/complete'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  900: {
    id: 900,
    _apiName: 'freshStartContact',
    action: linkCallback('/benefits/freshstart/contact'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  910: {
    id: 910,
    _apiName: 'freshStartAwaiting',
    action: linkCallback('/benefits/freshstart/awaiting'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  920: {
    id: 920,
    _apiName: 'freshStartNSLDSUpload',
    action: linkCallback('/benefits/freshstart/nslds'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  930: {
    id: 930,
    _apiName: 'freshStartComplete',
    action: linkCallback('/benefits/freshstart/complete'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  1000: {
    id: 1000,
    _apiName: 'eduReengagementDates',
    action: linkCallback('/benefits/educational-reengagement/overview'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Provide Dates',
  },
  1100: {
    id: 1100,
    _apiName: 'reducedIDRWelcome',
    action: linkCallback('/benefits/reducedidr/welcome'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  1200: {
    id: 1200,
    _apiName: 'reducedIDRGenerate',
    action: linkCallback('/benefits/reducedidr/idr/step-1'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  1300: {
    id: 1300,
    _apiName: 'reducedIDRSubmit',
    action: linkCallback('/benefits/reducedidr/idr/step-2'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  1400: {
    id: 1400,
    _apiName: 'reducedIDRReview',
    action: linkCallback('/benefits/reducedidr/idr/step-3'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  1500: {
    id: 1500,
    _apiName: 'reducedIDRComplete',
    action: linkCallback('/benefits/reducedidr/complete'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  1600: {
    id: 1600,
    _apiName: 'reducedIDRGenerateRenewal',
    action: linkCallback('/benefits/reducedidr/idrrenewal/step-1'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  1601: {
    id: 1601,
    _apiName: 'reducedIDRProfileVerification',
    action: linkCallback('/benefits/reducedidr/profile'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  1605: {
    id: 1605,
    _apiName: 'reducedIDRQuestionnaire',
    action: linkCallback('/benefits/reducedidr/questionnaire'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  1606: {
    id: 1606,
    _apiName: 'reducedIDRUpdateEmployment',
    action: linkCallback('/benefits/reducedidr/employmenthistory'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  1610: {
    id: 1610,
    _apiName: 'reducedIDRSubmitRenewal',
    action: linkCallback('/benefits/reducedidr/idrrenewal/step-2'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  1620: {
    id: 1620,
    _apiName: 'reducedIDRReviewRenewal',
    action: linkCallback('/benefits/reducedidr/idrrenewal/step-3'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  1630: {
    id: 1630,
    _apiName: 'reducedIDRCompleteRenewal',
    action: linkCallback('/benefits/reducedidr/completerenewal'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Go To Step',
  },
  2000: {
    id: 2000,
    _apiName: 'linkPlaidAccount',
    action: linkCallback('/plaid/overview'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Link Your Plaid Account',
  },
  2010: {
    id: 2010,
    _apiName: 'reconnectPlaidAccount',
    action: linkCallback('/plaid/overview'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Re-link Your Plaid Account',
  },
  2020: {
    id: 2020,
    _apiName: 'sharedDataConsentPlaid',
    action: modalCallback(SharedDataConsent),
    actionType: todoActionTypes.TODO_ACTION_MODAL,
    buttonText: 'Consent To Share Data',
  },
  2100: {
    id: 2100,
    _apiName: 'loanRepayment',
    action: linkCallback('/loan-repayments/overview'),
    actionType: todoActionTypes.TODO_ACTION_LINK,
    buttonText: 'Add',
  },
};
