import jwtDecode from 'jsonwebtoken/decode';

import { hasAnyTodos, hasTodo } from '../../todos';
import { safeAccess } from '../../utils';

import { benefits, benefitStatuses } from './benefits';
import clientStatuses from './statuses';

export const isAuthenticated = (state) => {
  const test =
    state.auth.cache !== null &&
    getToken(state) &&
    !isTokenExpired(getToken(state)) &&
    hasPermissions(state);
  return !!test;
};

export const passwordNeedsReset = (state) => {
  return safeAccess(state, 'auth.cache.permissions.passwordNeedsReset', false);
};

export const getThemeData = (state) => {
  return safeAccess(state, 'auth.cache.permissions.theme', false);
};

export const getPasswordResetTokenFromPermissions = (state) => {
  return safeAccess(state, 'auth.cache.permissions.passwordResetToken', '');
};

export const authGetRedirectPath = (
  state,
  isAuthenticated = false,
  passedSecurityCheck = false
) => {
  if (isAuthenticated) {
    const auth = state.auth.cache;
    if (
      auth &&
      auth.permissions &&
      auth.permissions.passwordNeedsReset &&
      auth.permissions.passwordNeedsReset === true
    ) {
      return '/password-out-of-date';
    } else if (
      !passedSecurityCheck &&
      auth &&
      auth.permissions &&
      auth.permissions.useMfa > 0 &&
      auth.permissions.securityQuestionsSetup === true
    ) {
      return '/security-question-check';
    } else if (
      !passedSecurityCheck &&
      auth &&
      auth.permissions &&
      auth.permissions.securityQuestionsSetup === false
    ) {
      return '/security-questions';
    } else if (
      auth &&
      auth.permissions &&
      auth.permissions.needsScreening &&
      auth.permissions.needsScreening === true &&
      !!auth.permissions.showSkipScreening &&
      auth.permissions.showSkipScreening === true
    ) {
      return '/prescreening';
    } else if (
      auth &&
      auth.permissions &&
      auth.permissions.needsScreening &&
      auth.permissions.needsScreening === true
    ) {
      return '/screening';
    } else {
      return 'referrer';
    }
  } else {
    return '/login';
  }
};

export const shouldSilentRenew = (state) => {
  const test =
    state.auth.cache !== null &&
    getToken(state) &&
    getRefreshToken(state) &&
    isTokenExpiring(getToken(state)) &&
    !renewInProgress(state);
  return !!test;
};

export const getToken = (state) => safeAccess(state, 'auth.cache.accessToken');
export const getRefreshToken = (state) => safeAccess(state, 'auth.cache.refreshToken');

const isTokenExpiring = (token) => {
  const decodedToken = jwtDecode(token, { complete: true });
  const now = new Date();
  const soon = new Date(now.setMinutes(now.getMinutes() + 10));
  return decodedToken.payload.exp < soon.getTime() / 1000;
};

const isTokenExpired = (token) => {
  const decodedToken = jwtDecode(token, { complete: true });
  const now = new Date();
  return decodedToken.payload.exp < now.getTime() / 1000;
};

const renewInProgress = (state) => state.auth.requests.silentRenew.isLoading;

/*export const getUserId = createSelector(
  [getToken],
  (token) => jwtDecode(token, { complete: true }).payload.sub
);*/

export const getUserId = (state) => {
  return getTokenId(state, 'C');
};

export const getCRMUserId = (state) => {
  return getTokenId(state, 'A');
};

export const getSecurityId = (state) => {
  return getTokenId(state, 'S');
};

const getTokenId = (state, idType) => {
  const token = getToken(state);
  if (token !== undefined && token !== null && token.length > 0) {
    const subject = jwtDecode(token, { complete: true }).payload.sub;
    if (subject !== undefined) {
      let startCharacter = subject.indexOf(idType);
      if (startCharacter >= 0) {
        startCharacter += 1;
        let userIdText = '';
        do {
          userIdText += subject[startCharacter];
          startCharacter++;
        } while (
          startCharacter < subject.length &&
          (subject[startCharacter] === '0' ||
            subject[startCharacter] === '1' ||
            subject[startCharacter] === '2' ||
            subject[startCharacter] === '3' ||
            subject[startCharacter] === '4' ||
            subject[startCharacter] === '5' ||
            subject[startCharacter] === '6' ||
            subject[startCharacter] === '7' ||
            subject[startCharacter] === '8' ||
            subject[startCharacter] === '9')
        );
        return userIdText;
      }
    }
  }
  return 'Error';
};

const hasPermissions = (state) => state.auth.cache.permissions != null;

export const isHomeAddressMissing = (state) => {
  const permissions = safeAccess(state, 'auth.cache.permissions');

  if (permissions) {
    return !permissions.hasHomeAddress;
  }
};

export const hasBenefit = (state, id) => {
  const permissions = safeAccess(state, 'auth.cache.permissions');
  const benefit = safeAccess(permissions, `benefits[${id}]`);

  if (permissions && benefit) {
    switch (benefit.benefitEnrollmentStatus) {
      case benefitStatuses.ENROLLED:
      case benefitStatuses.ENROLLING:
      case benefitStatuses.QUALIFIED:
        return true;
      case benefitStatuses.DECLINED:
      case benefitStatuses.INELIGIBLE:
      default:
        return false;
    }
  }
  return false;
};

const hasStatusInGroup = (statusGroup, status) => statusGroup.includes(status);

/*const hasToDo = (passedToDo) => {
  let todos = safeAccess(state, 'todos.cache', {});
  if (passedToDo in todos){
    return true;
  } else {
    return false
  }
}*/

export const isReadOnly = (state, id) => {
  const permissions = safeAccess(state, 'auth.cache.permissions');
  const benefit = safeAccess(permissions, `benefits[${id}]`);

  if (permissions && benefit) {
    switch (benefit.benefitEnrollmentStatus) {
      case benefitStatuses.READONLY:
        return true;
      case benefitStatuses.ENROLLING:
      case benefitStatuses.QUALIFIED:
      case benefitStatuses.DECLINED:
      case benefitStatuses.INELIGIBLE:
      default:
        return false;
    }
  }
  return false;
};

export const isDeclined = (state, id) => {
  const permissions = safeAccess(state, 'auth.cache.permissions');
  const benefit = safeAccess(permissions, `benefits[${id}]`);

  if (permissions && benefit) {
    switch (benefit.benefitEnrollmentStatus) {
      case benefitStatuses.DECLINED:
        return true;
      case benefitStatuses.ENROLLING:
      case benefitStatuses.QUALIFIED:
      case benefitStatuses.READONLY:
      case benefitStatuses.INELIGIBLE:
      default:
        return false;
    }
  }
  return false;
};

export const isEnrollingOrEnrolled = (state, id) => {
  const permissions = safeAccess(state, 'auth.cache.permissions');
  const benefit = safeAccess(permissions, `benefits[${id}]`);

  if (permissions && benefit) {
    switch (benefit.benefitEnrollmentStatus) {
      case benefitStatuses.ENROLLED:
      case benefitStatuses.ENROLLING:
        return true;
      case benefitStatuses.QUALIFIED:
      case benefitStatuses.DECLINED:
      case benefitStatuses.INELIGIBLE:
      default:
        return false;
    }
  }
  return false;
};

export const isEnrolled = (state, id) => {
  const permissions = safeAccess(state, 'auth.cache.permissions');
  const benefit = safeAccess(permissions, `benefits[${id}]`);

  if (permissions && benefit) {
    switch (benefit.benefitEnrollmentStatus) {
      case benefitStatuses.ENROLLED:
        return true;
      case benefitStatuses.ENROLLING:
      case benefitStatuses.QUALIFIED:
      case benefitStatuses.DECLINED:
      case benefitStatuses.INELIGIBLE:
      default:
        return false;
    }
  }
  return false;
};

export const isEnrolling = (state, id) => {
  const permissions = safeAccess(state, 'auth.cache.permissions');
  const benefit = safeAccess(permissions, `benefits[${id}]`);

  if (permissions && benefit) {
    switch (benefit.benefitEnrollmentStatus) {
      case benefitStatuses.ENROLLING:
        return true;
      case benefitStatuses.ENROLLED:
      case benefitStatuses.QUALIFIED:
      case benefitStatuses.DECLINED:
      case benefitStatuses.INELIGIBLE:
      default:
        return false;
    }
  }
  return false;
};

export const isInAuth = (status) => {
  if (status) {
    if (
      status === 'AGREE' ||
      status === 'PROC' ||
      status === 'CCONS' ||
      status === 'CSERV' ||
      status === 'VERFY' ||
      status === 'FPAGR' ||
      status === 'FCONS' ||
      status === 'FPCSR'
    ) {
      return true;
    }
  }
  return false;
};

export const hasBtsQuestionnaireCompleted = (state) => {
  const permissions = safeAccess(state, 'auth.cache.permissions');

  if (permissions) {
    if (permissions.forgivenessSteps) {
      let findReview = permissions.forgivenessSteps.find((a) => a.id === 'BtsReview');
      let findQuestionnaire = permissions.forgivenessSteps.find((a) => a.id === 'BtsQuestionnaire');
      let findWelcome = permissions.forgivenessSteps.find((a) => a.id === 'Welcome');

      if (findReview && !findQuestionnaire && !findWelcome) {
        return true;
      }
    }
  }
  return false;
};

export const isFullService = (state, id) => {
  const permissions = safeAccess(state, 'auth.cache.permissions');
  const benefit = safeAccess(permissions, `benefits[${id}]`);

  if (permissions && benefit && benefit.planType === 'FS') {
    return true;
  }
  return false;
};

export const getShowPolaris = (state) => {
  const permissions = safeAccess(state, 'auth.cache.permissions');
  let CRMUserId = getCRMUserId(state);

  if (permissions && CRMUserId !== 'Error') {
    if (permissions.polarisUser) {
      let isPolarisUser = permissions.polarisUser.find((a) => a.id === parseInt(CRMUserId));
      if (isPolarisUser) {
        return true;
      }
    }
  }

  return false;
};

export const getOptimalSituation = (state) => {
  let retVal = false;
  const permissions = safeAccess(state, 'auth.cache.permissions');
  if (permissions && permissions.enterpriseOptimalSituation) {
    retVal = true;
  }
  return retVal;
};

export const calculateCustomerPermissions = (state) => {
  const hasAccountServicers = hasBenefit(state, benefits.ACCOUNT_SERVICERS);
  const hasAccounts = hasBenefit(state, benefits.ACCOUNTS);
  const hasContribution = hasBenefit(state, benefits.CONTRIBUTION);
  const readOnlyContribution = isReadOnly(state, benefits.CONTRIBUTION);
  const hasIdentity = false; //hasBenefit(state, benefits.IDENTITY);
  const hasStudentLoanDisabilityInsurance = hasBenefit(
    state,
    benefits.STUDENT_LOAN_DISABILITY_INSURANCE
  );
  const hasScheduleMeeting = hasTodo(state, [1, 14, 203, 208]);
  const hasSharedData = hasBenefit(state, benefits.SHARED_DATA);

  const hasForgiveness = hasBenefit(state, benefits.FORGIVENESS);
  const inForgiveness = isEnrollingOrEnrolled(state, benefits.FORGIVENESS);
  const enrolledForgiveness = isEnrolled(state, benefits.FORGIVENESS);

  const hasLwo = hasBenefit(state, benefits.LWO);
  const inLwo = isEnrollingOrEnrolled(state, benefits.LWO);
  const enrolledLwo = isEnrolled(state, benefits.LWO);

  const btsQuestionnaireCompleted = hasBtsQuestionnaireCompleted(state);

  const hasRefinance = hasBenefit(state, benefits.REFINANCE);
  const inRefinance = isEnrollingOrEnrolled(state, benefits.REFINANCE);

  const hasSimplifiConsolidation = hasBenefit(state, benefits.SIMPLIFI_CONSOLIDATION);
  const hasSimplifiIdr = hasBenefit(state, benefits.SIMPLIFI_IDR);
  const hasSimplifi = hasSimplifiConsolidation || hasSimplifiIdr;
  const inSimplifiConsolidation = isEnrollingOrEnrolled(state, benefits.SIMPLIFI_CONSOLIDATION);
  const inSimplifiIdr = isEnrollingOrEnrolled(state, benefits.SIMPLIFI_IDR);
  const inSimplifi = inSimplifiConsolidation || inSimplifiIdr;

  const hasEnterpriseEmpCert = hasBenefit(state, benefits.EMPCERT);
  const hasFreshStart =
    hasBenefit(state, benefits.FRESHSTART) || isReadOnly(state, benefits.FRESHSTART);
  const hasReducedIDR = hasBenefit(state, benefits.REDUCEDIDR);
  const hasEducationalReengagement = hasBenefit(state, benefits.EDUCATIONALREENGAGEMENT);
  const hasOptimalSituation = getOptimalSituation(state);
  const enrolledFreshStart = isEnrolled(state, benefits.FRESHSTART);
  const enrolledEducationalReengagement = isEnrolled(state, benefits.EDUCATIONALREENGAGEMENT);
  const enrollingReducedIDR = isEnrolling(state, benefits.REDUCEDIDR);

  const showForgiveness =
    !hasEnterpriseEmpCert && ((hasForgiveness && !inSimplifi && !inRefinance) || inForgiveness);
  const showLwo = enrolledLwo; //hasLwo || inLwo || isDeclined(state, benefits.LWO);
  const showSimplifi =
    (hasSimplifiConsolidation || hasSimplifiIdr) &&
    !hasForgiveness &&
    !hasFreshStart &&
    !inRefinance;
  const showSimplifiConsolidation = hasSimplifiConsolidation && !hasForgiveness && !inRefinance;
  const showSimplifiIdr = hasSimplifiIdr && !hasForgiveness && !inRefinance;
  const showRefinance = hasRefinance && !inForgiveness && !inSimplifi;
  const showPolaris = getShowPolaris(state);

  const hasTuition = hasBenefit(state, benefits.TUITION);
  const inTuition = isEnrollingOrEnrolled(state, benefits.TUITION);
  const enrolledTuition = isEnrolled(state, benefits.TUITION);
  const readOnlyTuition = isReadOnly(state, benefits.TUITION);
  const qualifiedTuition = hasTuition && !inTuition;

  const homeAddressMissing = isHomeAddressMissing(state);
  const covidFurlough = safeAccess(state, 'auth.cache.permissions.covidFurlough', 0);

  const hasRetirement = hasBenefit(state, benefits.RETIREMENT);

  const clientStatus = safeAccess(state, 'auth.cache.permissions.status', {});
  const partnerId = safeAccess(state, 'auth.cache.permissions.strategicPartnerId', 0);

  const hasPayNow = clientStatus === 'OHPAY' || hasTodo(state, [5, 605, 700, 701, 702, 703, 704]);

  const hasRenewalPayNow = hasTodo(state, [204, 250]);

  const isLwoDeclined = isDeclined(state, benefits.LWO);

  const hasAnyBenefits =
    hasContribution ||
    hasForgiveness ||
    hasIdentity ||
    hasStudentLoanDisabilityInsurance ||
    hasRefinance ||
    hasRetirement ||
    hasSimplifi ||
    hasTuition ||
    hasLwo ||
    hasEnterpriseEmpCert ||
    hasFreshStart ||
    hasEducationalReengagement ||
    hasOptimalSituation ||
    hasReducedIDR;

  const isMarried = safeAccess(state, 'auth.cache.permissions.married', false);

  const consentFormCode = safeAccess(state, 'auth.cache.permissions.consentFormCode', {});

  const hasConsentToShareData = hasTodo(state, [6]);

  const electronicEmpcertEnabled = safeAccess(
    state,
    'auth.cache.permissions.electronicEmpcertEnabled',
    false
  );

  const enterpriseEnabled = safeAccess(state, 'auth.cache.permissions.enterpriseEnabled', false);
  const subscriptionAvailable = safeAccess(
    state,
    'auth.cache.permissions.subscriptionAvailable',
    false
  );
  const subscriptionActive = safeAccess(state, 'auth.cache.permissions.subscriptionActive', false);
  const hasBenefitTools = safeAccess(state, 'auth.cache.permissions.hasBenefitTools');
  const disableScheduling = safeAccess(state, 'auth.cache.permissions.disableScheduling', false);
  const hasMichiganProgram = safeAccess(state, 'auth.cache.permissions.hasMichiganProgram');
  const skipScreeningMessage = safeAccess(state, 'auth.cache.permissions.skipScreeningMessage', '');
  const showSkipScreening = safeAccess(state, 'auth.cache.permissions.showSkipScreening', false);
  const hasPlaid = safeAccess(state, 'auth.cache.permissions.hasPlaid', false);
  const hasLoanRepayment = safeAccess(state, 'auth.cache.permissions.hasLoanRepayment', false);
  const isConsolidationSubmitGeneratePaused = safeAccess(
    state,
    'auth.cache.permissions.isConsolidationSubmitgeneratePaused',
    false
  );
  const isConsolidationCheckPaused = safeAccess(
    state,
    'auth.cache.permissions.isConsolidationCheckPaused',
    false
  );

  const isEmpcertCheckPaused = safeAccess(
    state,
    'auth.cache.permissions.isEmpcertCheckPaused',
    false
  );

  const annualRecertDate = safeAccess(state, 'auth.cache.permissions.annualRecertDate', null);
  const planChangeIndicator = safeAccess(state, 'auth.cache.permissions.planChangeIndicator', null);
  const doeIdrCalculation = safeAccess(state, 'auth.cache.permissions.doeIdrCalculation', null);
  const hasEmployerPaidRenewal = safeAccess(
    state,
    'auth.cache.permissions.hasEmployerPaidRenewal',
    false
  );

  return {
    permissions: {
      btsQuestionnaireCompleted: btsQuestionnaireCompleted,
      clientStatus: clientStatus,
      contributionReadOnly: readOnlyContribution,
      forgivenessSteps: safeAccess(state, 'auth.cache.permissions.forgivenessSteps', null),
      hasAnyBenefits: hasAnyBenefits,
      hasAnyTodos: hasAnyTodos(state),
      hasTuition: hasTuition,
      hasForgiveness: hasForgiveness,
      inPreSale: hasStatusInGroup(clientStatuses.PRESALE, clientStatus),
      inAuth: isInAuth(clientStatus),
      isFullService: isFullService(state, benefits.FORGIVENESS),
      isMarried: isMarried,
      lwoSteps: safeAccess(state, 'auth.cache.permissions.lwoSteps', null),
      showAccounts: hasAccounts,
      showAccountServicers: hasAccountServicers,
      showContribution: hasContribution,
      showForgiveness: showForgiveness,
      showLwo: showLwo,
      isLwoDeclined: isLwoDeclined,
      inForgiveness: inForgiveness,
      inSimplifi: inSimplifi,
      enrolledForgiveness: enrolledForgiveness,
      enrolledLwo: enrolledLwo,
      hasLwo: hasLwo,
      strategicPartnerId: partnerId,
      showIdentity: hasIdentity,
      showPayNow: hasPayNow,
      showRenewalPayNow: hasRenewalPayNow,
      showRefinance: showRefinance,
      showRetirement: hasRetirement,
      showScheduleMeeting: hasScheduleMeeting,
      showSharedData: hasSharedData,
      showSimplifi: showSimplifi,
      showSimplifiConsolidation: showSimplifiConsolidation,
      showSimplifiIdr: showSimplifiIdr,
      showStudentLoanDisabilityInsurance: hasStudentLoanDisabilityInsurance,
      homeAddressIsMissing: homeAddressMissing,
      showTuition: hasTuition,
      showPolaris: showPolaris,
      tuitionReadOnly: readOnlyTuition,
      inTuition: inTuition,
      enrolledTuition: enrolledTuition,
      qualifiedTuition: qualifiedTuition,
      covidFurlough: covidFurlough,
      useMFA: safeAccess(state, 'auth.cache.permissions.useMfa', 0),
      securityQuestionsSetup: safeAccess(
        state,
        'auth.cache.permissions.securityQuestionsSetup',
        false
      ),
      forceSetup: safeAccess(state, 'auth.cache.permissions.forceSetup', false),
      consentFormCode: consentFormCode,
      hasConsentToShareData: hasConsentToShareData,
      electronicEmpcertEnabled: electronicEmpcertEnabled,
      hasEnterpriseEmpCert: hasEnterpriseEmpCert,
      hasFreshStart: hasFreshStart,
      hasReducedIDR: hasReducedIDR,
      hasEducationalReengagement: hasEducationalReengagement,
      hasOptimalSituation: hasOptimalSituation,
      enterpriseEmpcertSteps: safeAccess(
        state,
        'auth.cache.permissions.enterpriseEmpcertSteps',
        null
      ),
      enterpriseEnabled: enterpriseEnabled,
      freshStartSteps: safeAccess(state, 'auth.cache.permissions.freshStartSteps', null),
      reducedIdrSteps: safeAccess(state, 'auth.cache.permissions.reducedIdrSteps', null),
      enrolledFreshStart: enrolledFreshStart,
      enrolledEducationalReengagement: enrolledEducationalReengagement,
      enrollingReducedIDR: enrollingReducedIDR,
      subscriptionAvailable: subscriptionAvailable,
      subscriptionActive: subscriptionActive,
      hasBenefitTools: hasBenefitTools,
      disableScheduling: disableScheduling,
      hasMichiganProgram: hasMichiganProgram,
      showSkipScreening: showSkipScreening,
      skipScreeningMessage: skipScreeningMessage,
      isConsolidationSubmitGeneratePaused: isConsolidationSubmitGeneratePaused,
      isConsolidationCheckPaused: isConsolidationCheckPaused,
      isEmpcertCheckPaused: isEmpcertCheckPaused,
      annualRecertDate: annualRecertDate,
      plaidSteps: safeAccess(state, 'auth.cache.permissions.plaidSteps', null),
      loanRepaymentSteps: safeAccess(state, 'auth.cache.permissions.loanRepaymentSteps', null),
      hasPlaid: hasPlaid,
      hasLoanRepayment: hasLoanRepayment,
      doeIdrCalculation: doeIdrCalculation,
      planChangeIndicator: planChangeIndicator,
      hasEmployerPaidRenewal: hasEmployerPaidRenewal,
    },
  };
};

export const getBenefitStatus = (state, id) => {
  const permissions = safeAccess(state, 'auth.cache.permissions');
  const benefit = safeAccess(permissions, `benefits[${id}]`);

  if (permissions && benefit) {
    return benefit.benefitEnrollmentStatus;
  }
  return benefitStatuses.INELIGIBLE;
};

export const getCustomWelcomeMessageData = (state) => safeAccess(state, 'login.cache[0]', {});
