import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faExclamation as faSolidExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';

import {
  AsyncLoader,
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Modal,
  CardFooter,
} from 'fiducius-ui';

import { debounce, mergeRequestStatuses, pick, safeAccess } from '../../utils';
import { todosLoadCollection } from '../../todos';
import { withPermissions } from '../../routing';

import ServicerForm from '../components/servicer-form';
import ServicerTable from '../components/servicer-table';
import {
  servicersClearForm,
  servicersCopyResourceToForm,
  servicersUpdateResource,
  servicersHandleFormChange,
  servicersCreateResource,
} from '../redux/operations';

const StyledButton = styled(Button)`
  margin-right: 1em;
  margin-top: 1em;
`;

const StyledCardText = styled(CardText)`
  text-align: left;
`;

const PaddedIcon = styled(FontAwesomeIcon)`
  margin-right: 0.45em;
`;

const PaddedBadge = styled(Badge)`
  margin-right: 0.45em;
  font-size: 0.75em;
  top: -0.75rem;
`;

class Servicers extends React.Component {
  static propTypes = {
    actionRequest: t.object,
    clearForm: t.func.isRequired,
    createServicer: t.func.isRequired,
    errors: t.object,
    form: t.object,
    handleForm: t.func.isRequired,
    hasContributionAccountTypes: t.bool,
    hydrateForm: t.func.isRequired,
    permissions: t.object,
    refServicers: t.object,
    reload: t.func.isRequired,
    request: t.object,
    servicers: t.object,
    toDos: t.object,
    updateServicer: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      formId: null,
      showForm: false,
      showTuitionInstructions: false,
      showContributionInstructions: false,
    };
    this.handleInstructionsButton = this.handleInstructionsButton.bind(this);
  }

  componentDidUpdate(prevProps) {
    const wasLoading = prevProps.actionRequest.isLoading;
    const currently = this.props.actionRequest;
    if (wasLoading && !currently.isLoading && !currently.hasFailed) {
      this.setState({ ...this.state, showForm: false }, () => this.props.reload());
    }
  }

  closeForm = () => {
    this.props.clearForm();
    this.setState({ ...this.state, showForm: false });
  };

  onAddServicer = () => this.setState({ formId: null, showForm: true });

  onEditServicer = (id) => {
    this.props.hydrateForm(id);
    this.setState({ formId: id, showForm: true });
  };

  onSelectRef = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const id = e.target.value;
    const attributes = [
      'address1',
      'address2',
      'city',
      'email',
      'phone',
      'phoneExtension',
      'refServicerId',
      'routingNumber',
      'servicerName',
      'state',
      'zip',
    ];

    const template = id === '-1' ? {} : pick(this.props.refServicers[id], attributes);
    const copiedServicer = { refServicerId: id, ...template };

    this.props.clearForm();
    this.onChange(copiedServicer, null, 'refServicerId');
  };

  onChange = debounce((servicer, id, attribute) => {
    const { permissions } = this.props;
    let formData = this.props.form;
    formData['isFullService'] = permissions['isFullService'];
    formData['isTuition'] = permissions['isTuition'];
    formData['inForgiveness'] = permissions['inForgiveness'];

    this.props.handleForm({ ...formData, ...servicer }, this.getDependentAttributes(attribute));
  }, 250);

  getDependentAttributes = (attribute) => {
    switch (attribute) {
      case 'refServicerId':
        return [
          'refServicerId',
          'address1',
          'address2',
          'city',
          'email',
          'phone',
          'phoneExtension',
          'refServicerId',
          'routingNumber',
          'servicerName',
          'state',
          'zip',
        ];
      case 'question1Text':
      case 'question1Response':
        return ['question1Text', 'question1Response'];
      case 'question2Text':
      case 'question2Response':
        return ['question2Text', 'question2Response'];
      case 'question3Text':
      case 'question3Response':
        return ['question3Text', 'question3Response'];
      default:
        return [attribute];
    }
  };

  onCreate = () => this.props.createServicer();

  onUpdate = () => this.props.updateServicer(this.props.form.id);

  handleInstructionsButton = (instructionType) => {
    switch (instructionType) {
      case 'TR':
        this.setState({ showTuitionInstructions: !this.state.showTuitionInstructions });
        break;
      case 'CONTB':
        this.setState({ showContributionInstructions: !this.state.showContributionInstructions });
        break;
      default:
        break;
    }
  };

  getIconForAdd() {
    const { toDos } = this.props;

    if (Object.keys(toDos).includes('402') || Object.keys(toDos).includes('501')) {
      return (
        <PaddedBadge brand="primary">
          <FontAwesomeIcon icon={faSolidExclamation} />
        </PaddedBadge>
      );
    } else {
      return <PaddedIcon icon={faPlusCircle} fixedWidth />;
    }
  }

  render() {
    const {
      actionRequest,
      servicers,
      errors,
      form,
      hasContributionAccountTypes,
      permissions,
      refServicers,
      request,
    } = this.props;
    let refs = { '-1': 'Other', '': '' };
    Object.keys(refServicers).forEach((k) => {
      refs[refServicers[k].id] = refServicers[k].servicerName;
    });
    const { formId, showForm } = this.state;

    return (
      <>
        <Card>
          <CardBody>
            <CardTitle>Account Servicers / Banks</CardTitle>
            <div className="row">
              <div className="col">
                <StyledButton brand="primary" onClick={this.onAddServicer}>
                  {this.getIconForAdd()} Add Servicer / Bank
                </StyledButton>
                {/* {permissions.hasTuition && (
                  <StyledButton brand="primary" onClick={() => this.handleInstructionsButton('TR')}>
                    <FontAwesomeIcon icon={faQuestionCircle} fixedWidth /> Add Bank for Tuition
                    Reimbursement
                  </StyledButton>
                )} */}
                {permissions.showContribution && hasContributionAccountTypes && (
                  <StyledButton
                    brand="primary"
                    onClick={() => this.handleInstructionsButton('CONTB')}
                  >
                    <FontAwesomeIcon icon={faQuestionCircle} fixedWidth /> Add Servicer/Bank for
                    Contribution
                  </StyledButton>
                )}
              </div>
            </div>
          </CardBody>
          <AsyncLoader request={request} emptyMessage={'You have no Servicers / Banks on file.'}>
            {servicers && (
              <ServicerTable servicers={servicers} onEditServicer={this.onEditServicer} />
            )}
          </AsyncLoader>
          {showForm && (
            <ServicerForm
              action={formId ? 'Update' : 'Create'}
              closeForm={this.closeForm}
              data={form}
              errors={errors}
              isLoading={actionRequest.isLoading}
              isOpen={showForm}
              onChange={this.onChange}
              onSubmit={formId ? this.onUpdate : this.onCreate}
              refServicers={refs}
              selectRefServicer={this.onSelectRef}
            />
          )}
          <CardBody></CardBody>
        </Card>
        <Modal isOpen={this.state.showTuitionInstructions}>
          <Card>
            <CardBody>
              <CardTitle>Adding a Bank for Tuition Reimbursement</CardTitle>
              <StyledCardText>
                <ol>
                  <li>Click on "Add Servicer / Bank"</li>
                  <li>Select "Other" from the dropdown</li>
                  <li>Fill out all required information</li>
                </ol>
              </StyledCardText>
            </CardBody>
            <CardFooter>
              <Button brand="lowContrast" onClick={() => this.handleInstructionsButton('TR')}>
                Close
              </Button>
            </CardFooter>
          </Card>
        </Modal>
        <Modal isOpen={this.state.showContributionInstructions}>
          <Card>
            <CardBody>
              <CardTitle>Adding a Servicer/Bank for Contribution</CardTitle>
              <StyledCardText>
                <ol>
                  <li>Click on "Add Servicer / Bank"</li>
                  <li>Select a servicer or "Other" from the dropdown</li>
                  <li>Fill out all required information</li>
                </ol>
              </StyledCardText>
            </CardBody>
            <CardFooter>
              <Button brand="lowContrast" onClick={() => this.handleInstructionsButton('CONTB')}>
                Close
              </Button>
            </CardFooter>
          </Card>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { cache, errors, form, requests } = state.servicers;
  const refServicers = state.refServicers.cache;
  return {
    actionRequest: mergeRequestStatuses([requests.createResource, requests.updateResource]),
    errors: errors,
    form: form,
    refServicers: refServicers,
    request: requests.loadCollection,
    servicers: cache,
    toDos: safeAccess(state, 'todos.cache', {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearForm: () => dispatch(servicersClearForm()),
  createServicer: async () => {
    await dispatch(servicersCreateResource());
    dispatch(todosLoadCollection());
  },
  handleForm: (servicer, attributes) => dispatch(servicersHandleFormChange(servicer, attributes)),
  hydrateForm: async (id) => await dispatch(servicersCopyResourceToForm(id)),
  updateServicer: async (id) => {
    await dispatch(servicersUpdateResource(id));
    dispatch(todosLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(Servicers));
